import React from "react"
import Layout from '../components/Layout'
import { base } from '../components/base.module.css'
import "@fontsource/source-code-pro" 


const IndexPage = () => {
  return (
    <div className={base}>
      <title>Home</title>
      <Layout pageTitle="Ashish's home on the internet">
        <p>Welcome to my internet abode. You'll soon find here my blog, link garden, and more.</p>
      </Layout>
    </div>
  )
}

export default IndexPage;