import * as React from 'react'
import { Link } from 'gatsby'
import { container, navBar, navItem } from './layout.module.css'

const Layout = ({ pageTitle, children }) => (
    <div className={container}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400&display=swap" rel="stylesheet"></link>
      <title>{pageTitle}</title>
      <nav >
        <ul className={navBar}>
          <li className={navItem}><Link to="/">Home</Link></li>
          <li className={navItem}><Link to="/now">Now</Link></li>
          <li className={navItem}><Link to="/blog">Blog</Link></li>
          <li className={navItem}><Link to="/vouch">Recommendations</Link></li>
          <li className={navItem}><Link to="/about">About</Link></li>
        </ul>
      </nav>
      <main>
        <h1>{pageTitle}</h1>
        {children}
      </main>
    </div>
)

export default Layout